































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import BaseRepository from '@/core/HttpResources/BaseRepository';
import GenericAdd from '@/core/Generics/GenericAdd';
import KeyValue from '@/models/example/keyValue.model';

@Component
export default class KeyValueAdd extends GenericAdd<KeyValue> {
  constructor() {
    super();
    this.controller = 'KeyValue';
    this.model = new KeyValue();
  }
}
