import { Component, Vue } from 'vue-property-decorator';
import BaseRepository from '@/core/HttpResources/BaseRepository';
import Base from '@/models/base/Base.model';
import BaseVue from '@/core/BaseVue';
@Component
export default class GenericAdd<T extends Base> extends BaseVue {
    protected repository!: BaseRepository<T>;
    protected model!: T;
    protected controller!: string;
    protected saving = false;
    protected exitOnSave = true;
    protected modelInit!: T;
    constructor() {
        super();
    }
    created() {
        this.repository = new BaseRepository<T>(this.controller);
        this.modelInit = this.map<T>(this.model);
    }
    get modelDoNotChange() {
        return this.equals(this.modelInit, this.model);
    }

    get user() {
        return this.$store.state.user;
    }

    protected async validate() {
        try {
            var result = await this.$validator.validateAll();
            if (result) {
                this.add();
                return;
            }
            throw this.$validator.errors.items.map(e => e.msg);
        } catch (errors) {
            this.operationFailedErrors(errors);
        }
    }
    protected async add() {

        try {
            this.saving = true;
            let result = await this.repository.create(this.model);
            this.saving = false;
            this.$emit('add', result);
            this.clean();
            this.operationSuccess();

            if (this.exitOnSave)
                this.confirmedCancel();

        } catch (errors) {
            this.operationFailedErrors(errors);
        } finally {
            this.saving = false;
        }
    }

    protected clean() {
        setTimeout(() => this.$validator.errors.clear(), 10);
        this.model = this.map<T>(this.modelInit);
    }
    protected cancel() {
        if (this.modelDoNotChange) {
            this.confirmedCancel();
            return;
        }

        this.$buefy.dialog.confirm({
            title: 'Advertencia',
            type: 'is-warning',
            size: 'is-medium',
            hasIcon: true,
            confirmText: 'Salir',
            cancelText: 'No',
            message: '¿Seguro que desea salir de la sección?',
            onConfirm: () => this.confirmedCancel()
        })

    }

    protected confirmedCancel() {
        this.model = this.map<T>(this.modelInit);
        this.$router.push(`${this.$router.currentRoute.path.split('/agregar')[0]}`)
    }
}