























































































import { Component, Vue } from 'vue-property-decorator';
import BaseVue from '@/core/BaseVue';
import GenericEdit from '@/core/Generics/GenericEdit';
import KeyValue from '@/models/example/keyValue.model';

@Component
export default class KeyValueEdit extends GenericEdit<KeyValue> {
  constructor() {
    super();
    this.controller = 'KeyValue';
    this.model = new KeyValue();
  }
}
