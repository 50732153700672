import BaseEntity from '../base/BaseEntity.model';

export default class KeyValue extends BaseEntity{
    public name?: string | null;
    public value?: string | null;

    constructor(){
        super();
        this.init();
    }

    init(){
        this.name = null;
        this.value = null;
    }
}