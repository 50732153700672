
























import { Component, Vue, Watch } from "vue-property-decorator";
import GenericBTable from "@/core/Generics/GenericBTable";
import BTableColumn from "@/models/base/BTableColumn.model";
import { BTableColumnType } from "@/enums/BTableColumnType";
import KeyValue from '@/models/example/keyValue.model';

@Component
export default class KeyValueTableComponent extends GenericBTable<KeyValue> {

  created() {
    this.tableConfig.controller = "Test";
    this.formatColumns();
  }
  formatColumns() {
    let key = new BTableColumn("name", "Key", true);
    let value = new BTableColumn("value", "Value", true);
    this.tableConfig.insertColumns(key, value);
  }
}
